<template>
  <p class="desc-item">
    <span class="desc-item-label">{{ label }}：</span>
    <span
      :class="`desc-item-value ${lineCount
        ? 'desc-item-value-multiple'
        : 'desc-item-value-nolimit'}`
      "
      :style="lineCount?{height: `${20 * lineCount}px`}:{}"
    >
      <slot></slot>
    </span>
    <a v-if="link" :href="link" class="clickable" target="_blank">详情</a>
  </p>
</template>

<script>
export default {
  name: "DescItem",
  props: {
    label: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      default: "",
    },
    lineCount: {
      type: Number,
    },
  },
}
</script>
<style scoped lang="less">
.desc-item {
  /* margin: 12px 0; */
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0;
}
.desc-item-label {
  display: inline-block;
  color: #999999;
  margin-right: 16px;
  vertical-align: top;
}

.desc-item-value {
  display: inline-block;
  word-wrap : break-word;
  vertical-align: top;
  color: #333333;
  max-width: 85%;
}

.desc-item-value-oneline {
  overflow: hidden;
  white-space: nowrap;
  max-width: 80%;
  text-overflow: ellipsis;
}

.desc-item-value-multiple {
  /* height:  */
  /* display: -webkit-box; */
  -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  // &::after {
  //   display: block;
  //   position: absolute;
  //   background: white;
  //   content: "...";
  //   bottom: 0;
  //   right: 0;
  //   height: 20px;
  //   width: 25px;
  //   text-align:center;
  // }
}

.desc-item-value-nolimit {
  white-space: normal;
}

.clickable {
  cursor: pointer;
  color: #40adff;
}
</style>

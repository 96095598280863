<template>
  <el-dialog :title="$t('a2f8362')" :visible="show" @close="$emit('cancel')">
    <el-form ref="form" label-suffix=":" :model="values" :rules="rules">
      <el-form-item :label="$t('c39dc7d')" prop="date">
        <el-date-picker
          v-model="values.date"
          type="daterange"
          range-separator="~"
          value-format="timestamp"
          :start-placeholder="$t('c01cae1')"
          :end-placeholder="$t('0e0d505')"
          style="width: 280px"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">{{ $t('0a82a95') }}</el-button>
      <el-button type="primary" @click="handleConfirm">{{ $t('d1a4ef7') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: "Republish",
  props: {
    show: Boolean,
  },
  data() {
    return {
      values: {
        date: undefined
      },

    }
  },
  computed: {
    rules() {
      return {
        date: [{ required: true, message: this.$t('5775c2e') }]
      }
    }
  },
  methods: {
    handleCancel() {
      this.$emit("cancel")
    },
    handleConfirm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit("confirm", this.values)
        }
      })
    }
  }
}
</script>

<style>
</style>

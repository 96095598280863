<template>
  <div>
    <Card>
      <template v-slot:header>{{ $t('76b00ba') }}</template>
      <template v-slot:actions>
        <el-button
          v-if="showClose"
          style="
            height: 32px;
            padding: 8px 22px;
            border-color: rgba(0, 164, 255, 1);
          "
          @click="handleCloseClick"
        >
          <span style="color: rgba(0, 164, 255, 1); font-size: 14px">
            {{ $t('0f521c7') }}
          </span>
        </el-button>
        <el-button
          v-if="showTerminate"
          style="
            height: 32px;
            padding: 8px 22px;
            border-color: rgba(0, 164, 255, 1);
          "
          @click="handleCancelClick"
        >
          <span style="color: rgba(0, 164, 255, 1); font-size: 14px">
            {{ $t('3e5fd7d') }}
          </span>
        </el-button>
        <el-button
          v-if="showRerelease"
          style="
            height: 32px;
            padding: 8px 22px;
            border-color: rgba(0, 164, 255, 1);
          "
          @click="showRepublish = true"
        >
          <span style="color: rgba(0, 164, 255, 1); font-size: 14px">
            {{ $t('a2f8362') }}
          </span>
        </el-button>
      </template>
      <DisplayCard
        :data="data"
        show-actions
        type="detail"
        @delete="handleDelete"
        @edit="handleEdit"
      />
    </Card>

    <Card :body-style="{ marginTop: '20px' }">
      <template v-slot:header>{{ $t('6f1b4f4') }}</template>
      <el-table :data="data.requirementsOrderList || []" style="width: 100%">
        <el-table-column
          prop="date"
          :label=" $t('285e24a')"
          width="80"
          :formatter="(_, __, ___, i) => i + 1"
        />
        <el-table-column prop="partnerName" :label="$t('6f1b4f4')" />
        <el-table-column
          prop="partnerType"
          :label="$t('a2d6f3f')"
          :formatter="
            (_, __, value) => {
              return COOPERATION_TYPE[value]
            }
          "
        />
        <el-table-column prop="startTime" :label="$t('ec63026')" width="180" />
        <el-table-column prop="name" :label="$t('8630098')" />
        <el-table-column prop="phone" :label="$t('46da118')" width="120" />
        <el-table-column
          prop="status"
          :label="$t('5237d0d')"
          :formatter="(_, __, value) => COOPERATION_STATUS[value]"
        />
        <el-table-column v-if="showActions" :label="$t('f8de4c5')" width="100">
          <template slot-scope="scope">
            <el-button
              v-if="allowCommunicate(scope.row)"
              type="text"
              size="small"
              @click="handleComClick(scope.row)"
            >
              {{ $t('92c8446') }}
            </el-button>
            <el-button
              v-if="allowCooperate(scope.row)"
              type="text"
              size="small"
              @click="handleCooperClick(scope.row)"
            >
              合作
            </el-button>
            <!-- <el-button
              v-if="allowCompletion(scope.row)"
              type="text"
              size="small"
              @click="handleCompleteClick(scope.row)"
            >
              已完成
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- <ActionButton v-if="showCompleteButton" @click="handleCompleteClick">
        已完成
      </ActionButton> -->
    </Card>
    <Republish
      :show="showRepublish"
      @cancel="showRepublish = false"
      @confirm="handleRepublishConfirm"
    />
    <ReleaseFormDialog :dialog-data="dialogData" />
  </div>
</template>

<script>
import { Message } from 'element-ui'

import Card from '@/components/Card'
// import Icon from '@/components/Icon'
// import ActionButton from '@/components/ActionButton'
import ReleaseFormDialog from '@/components/ReleaseFormDialog'

import Republish from '../Republish'

import api from '@/api/request'

import DisplayCard from '../displayCard/index'

import { COOPERATION_TYPE, COOPERATION_STATUS } from '@/utils/constants'

export default {
  name: 'RequestDetail',
  components: {
    Card,
    // Icon,
    DisplayCard,
    // ActionButton,
    Republish,
    ReleaseFormDialog,
  },
  data() {
    return {
      COOPERATION_TYPE,
      COOPERATION_STATUS,
      data: {},
      showRepublish: false,
      dialogData: {
        title: '编辑需求',
        type: 'ReleaseDemand',
        id: undefined,
        dialogVisible: false,
      },
    }
  },
  computed: {
    showActions() {
      return (
        this.data.status === 'COMMUNICATING' ||
        this.data.status === 'ON_DISPLAY'
        // this.data.status === 'COOPERATION'
      )
    },
    showTerminate() {
      return this.data.status === 'COOPERATION'
    },
    showClose() {
      // return this.data.status === 'COMMUNICATING' || this.data.status === 'ON_DISPLAY'
      return this.data.status === 'COMMUNICATING'
    },
    showRerelease() {
      return this.data.status === 'END' || this.data.status === 'TIME_OUT'
    },
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    allowCooperate(row) {
      return row.status === 'SERVICE_COMMUNICATED'
    },
    allowCommunicate(row) {
      return row.status === 'TO_BE_COMMUNICATED'
    },
    allowCompletion(row) {
      return row.status === 'COOPERATION'
    },
    handleComClick(row) {
      api.setCommunicated(row.id).then((res) => {
        if (res) {
          this.getDetail()
        }
      })
    },
    handleCooperClick(row) {
      api.setCooperation(row.id).then((res) => {
        if (res) {
          this.getDetail()
        }
      })
    },
    handleCompleteClick(row) {
      api.setComplete(row.id).then((res) => {
        if (res) this.getDetail()
      })
    },
    handleCloseClick() {
      api.closeRequest(this.data.id).then((res) => {
        if (res) this.getDetail()
      })
    },
    handleCancelClick() {
      const order = this.data.requirementsOrderList.find((item) => {
        return item.status === 'COOPERATION'
      })
      console.log(order)
      api.cancelOrder(order.id).then((res) => {
        if (res) this.getDetail()
      })
    },
    handleDelete(id) {
      api.delete(id).then((res) => {
        this.$router.push('/content/request/manage')
      })
    },
    handleEdit(id) {
      this.dialogData.id = id
      this.dialogData.dialogVisible = true
      // this.$router.push(`/content/request/release?id=${id}`);
    },
    handleRepublishConfirm(values) {
      // console.log(date);
      api
        .republish(this.data.id, {
          startTime: values.date[0],
          endTime: values.date[1],
        })
        .then((res) => {
          if (res.code === '200' || res.code === '000000') {
            Message({
              message: res.message,
              type: 'success',
              duration: 2 * 1000,
            })
            this.showRepublish = false
            this.$router.push({
              path: '/content/request/manage',
            })
          } else {
            Message({
              message: res.message,
              type: 'error',
              duration: 2 * 1000,
            })
          }
        })
    },
    getDetail() {
      const id = this.$route.query.id
      if (id) {
        api.getDetail(id).then((res) => {
          this.data = res
        })
      }
    },
  },
}
</script>

<style>
</style>
